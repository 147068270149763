import React, {Component} from 'react';

export default class AboutPage extends Component {
    render() {
        return (
            <div className="About">
                Dragão Careca
            </div>
        );
    }
}
