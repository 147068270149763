import React, { Component } from "react";
import imgGridContainerTop from '../../assets/img/Paper/papelTopo.webp'
import imgGridContainerBottom from '../../assets/img/Paper/papelBaixo.webp';
import agradecimento_img from '../../assets/img/Patreon/agradecimento.webp';
import alistar_img from '../../assets/img/Patreon/alistar.webp';
import qrcode from '../../assets/img/Patreon/qrcode.webp';
import config from '../../config';

import './index.css';

export default class PatreonPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            supporters: [],
            isLoading: true,
            error: null,
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });

        fetch(config.patreonSupporters)
            .then(r => {
                if (r.ok) {
                    return r.text()
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
            .then(text => {
                let splittedNames = text.split("\n");
                splittedNames.sort();
                this.setState({ supporters: splittedNames, isLoading: false });
            })
            .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        const { supporters } = this.state;

        return (
            <div className="patreonApoiadores">
                <div className="patreonAgradecimento">
                    <img className="patreonAgradecimento_img" src={agradecimento_img} alt="Agradecimento aos apoiadores." />
                    <div className="patreon_btn_container">
                        {/* <a href={qrcode}>
                            <img className="qrcode_img" src={qrcode} alt="Picpay qrcode" />
                        </a> */}

                        <a href={config.patreonLink}>
                            <img className="patreonAlistar_img" src={alistar_img} alt="Aliste-se aqui!" />
                        </a>
                    </div>
                </div>

                <div className="patreonContainerTop" style={{ height: "120px" }}>
                    <img src={imgGridContainerTop} alt="." style={{ width: "100%", height: "100%" }} />
                    <h1 className="patreonTitle"> Lista de Apoiadores </h1>
                </div>

                <div className="patreonContainer patreonNameFont">
                    {supporters.map((sup, i) => <p key={i}> {sup} </p>)}
                </div>

                <div className="patreonContainerBottom" style={{ backgroundImage: `url(${imgGridContainerBottom})`, marginTop: "-1px", height: "80px" }}></div>
            </div>
        );
    }
}
