import React from 'react';
import HeadBar from './components/HeadBar';
import './App.css';
import { HashRouter, Route } from 'react-router-dom';

import HomePage from './components/PageHome/Home';
import AboutPage from './components/PageAbout';
import EpisodePage from './components/PageEpisode/EpisodePage';
import PatreonPage from './components/PagePatreon/index';
import Footer from './components/Footer';
import './App.css';
import './assets/fonts/index.css';

function App() {
	return (
		<HashRouter className="App">
			<div className="PageContent">
				<HeadBar />

				<div className='content'>
					<Route exact path="/" component={HomePage} />
					<Route path="/about" component={AboutPage} />
					<Route path="/episode/:id" component={EpisodePage} />
					<Route path="/guilda" component={PatreonPage} />
				</div>

				<Footer />
			</div>

		</HashRouter>
	);
}

export default App;
