import React, { Component } from 'react';
import EpisodeGrid from './EpisodeGrid/EpisodeGrid';
import CharactersCanvasV2 from './CharactersCanvasV2/CharactersCanvasV2';
import config from '../../config';

export default class HomePage extends Component {
    render() {
        return (
            <div className="Home">
                <CharactersCanvasV2 />
                <EpisodeGrid maxEpisodesPerPage={config.maxEpisodesPerPage} />
            </div>
        );
    }
}
