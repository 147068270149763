import React from 'react';
import './SpotifyCard.css';

export default function SpotifyCard(props) {
    return (
        <div className="spotifyCard">
            <iframe src={"https://open.spotify.com/embed-podcast/episode/" + props.spotify_id}
                width="70%"
                height="232"
                frameBorder="0"
                allowtransparency="true"
                allow="encrypted-media"
                title={props.title} />
        </div>
    );
}