import { DiscussionEmbed } from "disqus-react";
import React from 'react';
import config from "../../../config";
import './CommentSection.css';

const DisqusComments = ({ post }) => {
    // Lib has bug, need to use in this format
    const url = `https://dragaocareca.com/episode_${post.id}`;
    const env = url.includes('localhost') ? 'dev' : 'prod';
    const identifier = `episode_${post.id.toString()}_${env}`;
    
    console.log(post);
    console.log(url);
    console.log(identifier);

    return (
        <div className="commentSection">
            <DiscussionEmbed
                shortname={config.disqus.shortName}
                config={{
                    url,
                    identifier, // Single post id
                    title: post.title // Single post title
                }}
            />
        </div>
    )
}

export default DisqusComments;