import React, {Component} from 'react';
// import SocialButtons from '../SocialBar/social';
// import config from '../../config';
import './index.css';

export default class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                {/* <div className="contact">
                    <p className="footerMessage"> Portais para outros mundos </p>
                    <SocialButtons className="socialIcons" />
                    <div className="emailBox">
                        <a href={`mailto:${config.email}`} > {config.email} </a>
                    </div>
                    <br/>
                </div> */}
            </footer>  
        );
    }
}