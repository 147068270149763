import React, { Component } from 'react';
import './EpisodeGrid.css';

import { Grid } from '@material-ui/core';
// import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import imgGridContainerBottom from '../../../assets/img/Paper/papelBaixo.webp';
import imgGridContainerTop from '../../../assets/img/Paper/papelTopo.webp';
import config from '../../../config';
import CrowdFundingBar from './CrowdFundingBar/crowdFundingBar';
import EpisodeCard from './EpisodeCard/EpisodeCard';
import SearchBar from './SearchBar/SearchBar';
import SocialButtonsForTop from './SocialButtonsForTop/socialButtonsForTop';

export default class EpisodeGrid extends Component {
    constructor(props) {
        super(props)

        this.state = {
            episodes: [],
            isLoading: true,
            error: null,
            page: 0,
            filteredList: [],
            filteredListInParts: []
        };

        this.replaceEpisodesList = this.replaceEpisodesList.bind(this);
        this.loadMoreEpisodes = this.loadMoreEpisodes.bind(this);
        this.loadMoreRef = React.createRef(null);

        // Obsolete: next/previous buttons functions
        // this.nextPage = this.nextPage.bind(this);
        // this.previousPage = this.previousPage.bind(this);
    }

    // Obsolete: next/previous buttons functions
    // nextPage() {
    //     if ((this.state.page + 1) * this.props.maxEpisodesPerPage >= this.state.episodes.length) {
    //         return
    //     }

    //     this.setState({
    //         page: this.state.page + 1,
    //     })
    // }

    // previousPage() {
    //     if (this.state.page === 0) {
    //         return
    //     }

    //     this.setState({
    //         page: this.state.page - 1,
    //     })
    // }

    replaceEpisodesList = (filteredList) => {
        if (filteredList.length <= this.props.maxEpisodesPerPage) {
            this.setState({
                filteredListInParts: filteredList,
            });
        } else {
            this.setState({
                filteredListInParts: filteredList.slice(0, this.props.maxEpisodesPerPage)
            });
        }

        this.setState({
            filteredList: filteredList
        });
    }

    loadMoreEpisodes() {
        if (this.state.filteredList === undefined) return;
        if (this.state.filteredListInParts.length >= this.state.filteredList.length) return;

        var filteredListLength = this.state.filteredList.length;
        var filteredListInPartsLength = this.state.filteredListInParts.length;
        var maxEpisodesPerPage = this.props.maxEpisodesPerPage;
        var endIndex = filteredListInPartsLength + maxEpisodesPerPage;
        var indexToIncrement = endIndex < filteredListLength ? endIndex : filteredListLength;

        var auxList = this.state.filteredListInParts;
        auxList.push(...this.state.filteredList.slice(filteredListInPartsLength, indexToIncrement));

        this.setState({
            filteredListInParts: auxList
        });
    }

    componentDidMount() {
        this.setState({ isLoading: true });

        fetch(config.api)
            .then(response => {
                if (response.ok) return response.json()
                else throw new Error('Something went wrong ...')
            })
            .then(data => {
                let episodes = data
                    .filter(episode => new Date(episode.pubDate) < new Date())
                    .reverse()

                this.setState({ episodes: episodes, filteredList: episodes, isLoading: false })
            })
            .catch(error => this.setState({ error, isLoading: false }));

        this.setState({ isLoading: false });
    }

    render() {

        const { isLoading, error, filteredListInParts } = this.state;
        const observer = new IntersectionObserver((entities) => {
            var target = entities[0];

            if (target.isIntersecting) {
                this.loadMoreEpisodes();
            }

        }, null);
        let message = "Carregando episódios...";

        if (this.state.filteredListInParts.length === this.state.filteredList.length) {
            message = "";
        }

        if (this.loadMoreRef.current) {
            observer.observe(this.loadMoreRef.current);
        }

        if (error) {
            return <p>{error.message}</p>;
        }

        if (isLoading) {
            return <p>Loading ...</p>;
        }

        this.lowerBound = this.state.page * this.props.maxEpisodesPerPage
        this.upperBound = (this.state.page + 1) * this.props.maxEpisodesPerPage

        return (
            <div className="gridComponent">
                <div className="gridContainerTopDiv">
                    <div className="social">
                        <CrowdFundingBar />
                        <SocialButtonsForTop />
                    </div>
                    {/* <Banner episodes={this.state.episodes} /> */}
                </div>
                <div className="gridContainerTop" style={{ height: "120px" }}>
                    <img src={imgGridContainerTop} alt="" style={{ width: "100%", height: "100%" }} />
                    <h1 className="titlePodcast"> Episódios </h1>
                    <SearchBar episodes={this.state.episodes} replaceEpisodesList={this.replaceEpisodesList} />
                </div>
                <div className="gridContainer">
                    <Grid container
                        className="EpisodesGrid"
                        justifyContent="center"
                    >
                        {filteredListInParts.map(epi =>
                            <EpisodeCard key={epi.ID} epi={epi} />
                        )}
                    </Grid>
                    {/* Obsolete: next/previous buttons */}
                    {/* <div className="buttonsDiv" >
                        <ButtonGroup className="buttons">
                        <Button onClick={this.previousPage}> <NavigateBefore /> </Button>
                        <Button onClick={this.nextPage}> <NavigateNext />  </Button>
                        </ButtonGroup>
                    </div> */}
                    <p className="loadingMessage" ref={this.loadMoreRef}>{message}</p>
                </div>
                <div className="gridContainerBottom" style={{ backgroundImage: `url(${imgGridContainerBottom})`, marginTop: "-1px", height: "80px" }}></div>
            </div>
        );
    }
}