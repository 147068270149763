import React, { Component } from 'react';
import './Character.css';

export default class Character extends Component {

    handleClick(e) {
        window.open(this.props.sheetUrl)
    }

    render() {
        var style = {
            'zIndex': this.props.zIndex,
            'marginLeft': this.props.marginLeft,
            'marginRight': this.props.marginRight
        }

        return (
            <div className="characterContainer"
                onClick={(e) => this.handleClick(e)}
            >
                <img className="characterImage"
                    src={this.props.imageUrl}
                    alt={this.props.alt}
                    style={style}
                >
                </img>
            </div>
        )
    }
}