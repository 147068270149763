import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// All the following keys are optional.
// We try our best to provide a great default value.
const theme = createTheme({
    palette: {
        primary: {
            main: '#ffffff',
            type: 'dark',
        },
        secondary: {
            main: '#C19A6B',
        },
    },
    typography: {
        useNextVariants: true
    }
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}><App /></MuiThemeProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
