import React, { Component } from 'react';
import config from '../../../../config';

export default class CrowdFundingBar extends Component {
    handleClick(e, href) {
        window.open(href, "_blank");
    }

    render() {
        return (
            <div className="socialForTop">
                <div className="contact">
                    <p className="footerMessage">Hey, você!</p>
                    <p className='footerMessage'>Quer se tornar um aventureiro da guilda do Dragão Careca? </p>
                    <div className="emailBox">Nos apoie se inscrevendo <a href={config.patreonLink} >aqui! </a></div>
                    <br />
                </div>
            </div>
        );
    }
}