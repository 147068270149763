import React from 'react';
// import { Card, CardContent, CardActionArea } from '@material-ui/core';
import { Link } from 'react-router-dom';
import config from '../../../../config';
import './EpisodeCard.css';
import imagePlayingSound from '../../../../assets/img/EpisodeGrid/centerwhite.webp'

export default function EpisodeCard(props) {

  const episodeImgPath = config.episodeImg + "low/" + props.epi.ID + ".webp";
  const hasTrailer = props.epi.ID >= config.episodeTrailerStartId;
  const audio = hasTrailer ? new Audio(config.episodeTrailer + "trailer_" + props.epi.ID + ".mp3") : null;

  return (
    <div className="cardBorder">
      <div className="cardDiv boxShadow"
        onMouseEnter={console.log()}
      >
        <Link to={"/episode/" + props.epi.ID} target="_blank">
          <div className="card">
            <div className="cover">
              <img src={episodeImgPath}
                alt={props.epi.title}
                style={{
                  backgroundImage: `url(${episodeImgPath})`,
                  backgroundSize: `100% 100%`
                }}
                onMouseEnter={e => {
                  if (hasTrailer) {
                    audio.play();
                    e.currentTarget.src = imagePlayingSound;
                  }
                }}
                onMouseLeave={e => {
                  if (hasTrailer) {
                    audio.pause();
                    e.currentTarget.src = episodeImgPath;
                  }
                }}
              />
            </div>
            <div className="cardContent">
              <div className="cardTitle">{props.epi.title}</div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
