import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkIcon from '@material-ui/icons/Link';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import React, { Component } from 'react';
import './citation.css';

export default class CitationItem extends Component {
    handleClick(e, href) {
        window.open(href, "_blank");
    }

    render() {
        let cite = this.props.cite;
        let contacts = cite.contacts;
        let socials = [];

        if (contacts != null) {
            Object.keys(contacts).map((key) => {
                switch (key) {
                    case "twitter":
                        socials.push(
                            <IconButton color="inherit" className="iconStyle" key={key} title={contacts[key]} onClick={(e) => this.handleClick(e, `${contacts[key]}`)}>
                                <TwitterIcon />
                            </IconButton>
                        )
                        break;
                    case "facebook":
                        socials.push(
                            <IconButton color="inherit" className="iconStyle" key={key} title={contacts[key]} onClick={(e) => this.handleClick(e, `${contacts[key]}`)}>
                                <FacebookIcon />
                            </IconButton>
                        )
                        break;
                    case "instagram":
                        socials.push(
                            <IconButton color="inherit" className="iconStyle" key={key} title={contacts[key]} onClick={(e) => this.handleClick(e, `${contacts[key]}`)}>
                                <InstagramIcon />
                            </IconButton>
                        )
                        break;
                    case "youtube":
                        socials.push(
                            <IconButton color="inherit" className="iconStyle" key={key} title={contacts[key]} onClick={(e) => this.handleClick(e, `${contacts[key]}`)}>
                                <YouTubeIcon />
                            </IconButton>)
                        break;
                    default:
                        socials.push(
                            <IconButton color="inherit" className="iconStyle" key={key} title={contacts[key]} onClick={(e) => this.handleClick(e, `${contacts[key]}`)}>
                                <LinkIcon />
                            </IconButton>
                        )
                }

                return "";
            });
        }

        return <div className="personItem">
            <div className="personName"> {this.props.mark ? <this.props.mark /> : ""} {cite.name} {cite.character ? `(${cite.character})` : ""}
                {socials.map((value) => { return value })}
            </div>
            <div className="description">
                {cite.description ? cite.description : ""}
            </div>
        </div>;
    }
}