var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _createSvgIcon = _interopRequireDefault(require("@material-ui/icons/utils/createSvgIcon"));

var _default = (0, _createSvgIcon.default)(_react.default.createElement("path", {
  d: "M 24 4 L 24 6 L 28 6 L 28 4 Z M 14 7 L 14 9 L 18 9 L 18 7 Z M 24 7 L 24 9 L 28 9 L 28 7 Z M 14 10 L 14 12 L 18 12 L 18 10 Z M 24 10 L 24 12 L 28 12 L 28 10 Z M 14 13 L 14 15 L 18 15 L 18 13 Z M 24 13 L 24 15 L 28 15 L 28 13 Z M 4 16 L 4 18 L 8 18 L 8 16 Z M 14 16 L 14 18 L 18 18 L 18 16 Z M 19 16 L 19 18 L 23 18 L 23 16 Z M 24 16 L 24 18 L 28 18 L 28 16 Z M 4 19 L 4 21 L 8 21 L 8 19 Z M 9 19 L 9 21 L 13 21 L 13 19 Z M 14 19 L 14 21 L 18 21 L 18 19 Z M 19 19 L 19 21 L 23 21 L 23 19 Z M 24 19 L 24 21 L 28 21 L 28 19 Z M 4 22 L 4 24 L 8 24 L 8 22 Z M 9 22 L 9 24 L 13 24 L 13 22 Z M 14 22 L 14 24 L 18 24 L 18 22 Z M 19 22 L 19 24 L 23 24 L 23 22 Z M 24 22 L 24 24 L 28 24 L 28 22 Z M 4 25 L 4 27 L 8 27 L 8 25 Z M 9 25 L 9 27 L 13 27 L 13 25 Z M 14 25 L 14 27 L 18 27 L 18 25 Z M 19 25 L 19 27 L 23 27 L 23 25 Z M 24 25 L 24 27 L 28 27 L 28 25 Z"
}), 'Deezer');

exports.default = _default;