import React, { Component } from 'react';
import { getCharacterList } from './CharacterList';
import './CharactersCanvasV2.css';

export default class CharactersCanvasV2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            characterList: [],
            isLoading: false,
            error: null,
        };
    }

    static getDerivedStateFromProps() {
        return {
            characterList: getCharacterList()
        };
    }

    render() {
        const { characterList, isLoading, error } = this.state;


        if (error) {
            return <p>{error.message}</p>;
        }

        if (isLoading) {
            return <p>Loading ...</p>;
        }

        return (
            <div className="characterCanvasContainer ContentPage">
                <div className="charactersList">
                    {characterList}
                </div>
            </div>
        );
    }
}