import React, { Component } from 'react';
import './SearchBar.css';

export default class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.filterEpisodeOnChange = this.filterEpisodeOnChange.bind(this);
    }

    filterEpisodeOnChange = (event) => {
        if (event.target.value.trim() === "") {
            this.props.replaceEpisodesList(this.props.episodes);
        } else {
            let filteredByTitle = this.props.episodes.filter(episode =>
                episode.title.toLowerCase().includes(event.target.value.toLowerCase()))

            let filteredByGuestName = this.props.episodes.filter(episode =>
                episode.guests?.some(guest =>
                    guest.name?.toLowerCase().includes(event.target.value.toLowerCase())))

            let distinctMergedFilteredList = filteredByTitle.concat(filteredByGuestName.filter(item => filteredByTitle.indexOf(item) < 0))

            this.props.replaceEpisodesList(distinctMergedFilteredList);
        }
    }

    render() {
        return (
            <div className="SearchBar">
                <label htmlFor="search">
                    <span role='img' aria-label="magnifier">🔎</span>
                </label>
                <input type="text" value={this.inputValue} onChange={this.filterEpisodeOnChange} placeholder="Título ou convidado" />
            </div>
        );
    }
}
