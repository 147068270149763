const config = {
    api: "https://api.dragaocareca.com/index.php",
    apiContacts: "https://api.dragaocareca.com/contacts.php",
    episodeFiles: "https://www.dragaocareca.com/files/episodes/",
    episodeImg: "https://www.dragaocareca.com/files/images/",
    episodeTrailer: "https://www.dragaocareca.com/files/trailers/",
    episodeTrailerStartId: 55,
    social: {
        twitter: "https://twitter.com/Dragao_Careca",
        youtube: "https://www.youtube.com/channel/UCq-TjauoYJrr3po121gA6iw?sub_confirmation=1",
        instagram: "https://www.instagram.com/dragaocareca",
        facebook: "https://www.facebook.com/dragaocareca",
        spotify: "https://open.spotify.com/show/4uTtWo6e9TIDnAy5r6UWIZ",
        deezer: "https://www.deezer.com/br/show/615692",
        googlePodcasts: "https://podcasts.google.com/?feed=aHR0cDovL2ZlZWQuZHJhZ2FvY2FyZWNhLmNvbS8",
        applePodcasts: "https://podcasts.apple.com/br/podcast/drag%C3%A3o-careca/id1482299800",
        pocketCast: "https://pca.st/17w8yhs3",
        castBox: "https://castbox.fm/ch/2414107",
        rss: "https://feed.dragaocareca.com"
    },
    maxEpisodesPerPage: 10,
    transitionTime: 7000,
    email: "contato@dragaocareca.com",
    patreonLink: "http://bit.ly/guildadc",
    patreonSupporters: "https://api.dragaocareca.com/patreon.php",
    fichasDominio: "https://ficha.dragaocareca.com/#",
    fichasRequest: "https://ficha.dragaocareca.com/#",
    disqus: {
        shortName: "dragaocareca"
    }
}

export default config;
