import React, { Component } from 'react';
import SocialButtons from './socialButtons';
import './socialButtonsForTop.css';
import config from '../../../../config';

export default class SocialButtonsForTop extends Component {
    handleClick(e, href) {
        window.open(href, "_blank");
    }

    render() {
        return (
            <div className="socialForTop">
                <div className="contact">
                    <p className="footerMessage"> Portais para outros mundos </p>
                    <SocialButtons className="socialIcons" />
                    <div className="emailBox">
                        Nos envie um pergaminho! <a href={`mailto:${config.email}`} > {config.email} </a>
                    </div>
                    <br />
                </div>
            </div>
        );
    }
}
