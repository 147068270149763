import React from 'react'
import Character from './Character/Character'
import characterTiamat from '../../../assets/img/CharactersCanvas/Tiamat.webp'
import characterAldabonero from '../../../assets/img/CharactersCanvas/Aldabonero.webp'
import characterTroah from '../../../assets/img/CharactersCanvas/Troah.webp'
import characterKavartu from '../../../assets/img/CharactersCanvas/Kavartu.webp'
import characterBron from '../../../assets/img/CharactersCanvas/Bron.webp'
import characterBaldur from '../../../assets/img/CharactersCanvas/Baldur.webp'
import characterLusa from '../../../assets/img/CharactersCanvas/Lusa.webp'

import config from '../../../config';

export var getCharacterList = function () {
    return (
        [
            <Character
                key="1"
                imageUrl={characterBron}
                sheetUrl={config.fichasRequest + "Bron"}
                alt="Imagem clicável do personagem Bron, O Bárbaro. Clicar no personagem redicionará à ficha desse personagem."
                zIndex="0"
            />,
            <Character
                key="2"
                imageUrl={characterTroah}
                sheetUrl={config.fichasRequest + "Troah"}
                alt="Imagem clicável do personagem Troah, O Bardo. Clicar no personagem redicionará à ficha desse personagem."
                zIndex="0"
            />,
            <Character
                key="3"
                imageUrl={characterKavartu}
                sheetUrl={config.fichasRequest + "Kavartu"}
                alt="Imagem clicável do personagem Kavartu, O Desenhista. Clicar no personagem redicionará à ficha desse personagem."
                zIndex="0"
            />,
            <Character
                key="4"
                imageUrl={characterTiamat}
                sheetUrl={config.fichasRequest + "Tiamat"}
                alt="Imagem clicável do personagem Tiamat, O Careca. Clicar no personagem redicionará à ficha desse personagem."
                zIndex="0"
            />,
            <Character
                key="5"
                imageUrl={characterAldabonero}
                sheetUrl={config.fichasRequest + "Aldabonero"}
                alt="Imagem clicável do personagem Aldabonero, O Colecionador. Clicar no personagem redicionará à ficha desse personagem."
                zIndex="0"
            />,
            <Character
                key="6"
                imageUrl={characterBaldur}
                sheetUrl={config.fichasRequest + "Baldur"}
				alt="Imagem clicável do personagem Baldur, O Paladino. Clicar no personagem redicionará à ficha desse personagem."
                zIndex="0"
            />,
            <Character
                key="7"
                imageUrl={characterLusa}
                sheetUrl={config.fichasRequest + "Lusa"}
				alt="Imagem clicável do personagem Lusa, A Druida. Clicar no personagem redicionará à ficha desse personagem."
                zIndex="0"
            />
        ]
    )
}
