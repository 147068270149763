import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import './authors.css';

export default class AuthorItem extends Component {
    handleClick(e, href) {
        window.open(href, "_blank");
    }

    render() {
        let author = this.props.author;

        if (!this.props.validAuthors.includes(author.name)) {
            return "";
        }

        let facebook = (author.contacts["facebook"] != null) ? <IconButton color="inherit" className="iconStyle"
            onClick={(e) => this.handleClick(e, `${author.contacts["facebook"]}`)}>
            <FacebookIcon />
        </IconButton> : "";

        let instagram = (author.contacts["instagram"] != null) ? <IconButton color="inherit" className="iconStyle"
            onClick={(e) => this.handleClick(e, `${author.contacts["instagram"]}`)}>
            <InstagramIcon />
        </IconButton> : "";

        let twitter = (author.contacts["twitter"] != null) ? <IconButton color="inherit" className="iconStyle"
            onClick={(e) => this.handleClick(e, `${author.contacts["twitter"]}`)}>
            <TwitterIcon />
        </IconButton> : "";

        return <div className="personItem">
            <div className="personName"> {author.name} {author.character ? `(${author.character})` : ""}{facebook} {twitter} {instagram}</div>
        </div>;
    }
}