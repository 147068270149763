import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import SpotifyIcon from './SocialIcons/Spotify';
import DeezerIcon from './SocialIcons/Deezer';
import GooglePodcastsIcon from './SocialIcons/GooglePodcasts';
import CastBoxIcon from './SocialIcons/CastBox';
import RSSIcon from '@material-ui/icons/RssFeed';
import config from '../../../../config';
import ApplePodcastsIcon from './SocialIcons/ApplePodcasts';
import PocketCastIcon from './SocialIcons/PocketCast';
import InstagramIcon from '@material-ui/icons/Instagram'

export default class SocialButtons extends Component {
    handleClick(e, href) {
        window.open(href, "_blank");
    }

    render() {
        return (
            <div>
                <IconButton color="inherit" className={this.props.iconstyle}
                    onClick={(e) => this.handleClick(e, config.social.spotify)}>
                    <SpotifyIcon />
                </IconButton>

                <IconButton color="inherit" className={this.props.iconstyle}
                    onClick={(e) => this.handleClick(e, config.social.instagram)}>
                    <InstagramIcon />
                </IconButton>

                <IconButton component="button" color="inherit" className={this.props.iconstyle}
                    onClick={(e) => this.handleClick(e, config.social.twitter)}>
                    <TwitterIcon />
                </IconButton>

                <IconButton color="inherit" className={this.props.iconstyle}
                    onClick={(e) => this.handleClick(e, config.social.deezer)}>
                    <DeezerIcon />
                </IconButton>
                
                <IconButton color="inherit" className={this.props.iconstyle}
                    onClick={(e) => this.handleClick(e, config.social.googlePodcasts)}>
                    <GooglePodcastsIcon />
                </IconButton>

                <IconButton color="inherit" className={this.props.iconstyle}
                    onClick={(e) => this.handleClick(e, config.social.castBox)}>
                    <CastBoxIcon />
                </IconButton>

                <IconButton color="inherit" className={this.props.iconstyle}
                    onClick={(e) => this.handleClick(e, config.social.facebook)}>
                    <FacebookIcon />
                </IconButton>

                <IconButton color="inherit" className={this.props.iconstyle}
                    onClick={(e) => this.handleClick(e, config.social.youtube)}>
                    <YouTubeIcon />
                </IconButton>

                <IconButton color="inherit" className={this.props.iconstyle}
                    onClick={(e) => this.handleClick(e, config.social.applePodcasts)}>
                    <ApplePodcastsIcon />
                </IconButton>

                <IconButton color="inherit" className={this.props.iconstyle}
                    onClick={(e) => this.handleClick(e, config.social.pocketCast)}>
                    <PocketCastIcon />
                </IconButton>

                <IconButton color="inherit" className={this.props.iconstyle}
                    onClick={(e) => this.handleClick(e, config.social.rss)}>
                    <RSSIcon />
                </IconButton>
            </div>
        );
    }
}