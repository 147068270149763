import React, { Component } from 'react';
import { CardMedia } from '@material-ui/core';
import AuthorItem from '../PageHome/EpisodeGrid/CreditsItems/authors';
import CitationItem from '../PageHome/EpisodeGrid/CreditsItems/citation';
import { ArtEmoji, CitationEmoji, MusicEmoji, AuthorEmoji, ContactsEmoji, PersonEmoji, MusicItemEmoji } from '../Utils/Emojis/index';
import imgGridContainerBottom from '../../assets/img/Paper/papelBaixo.webp';
import config from '../../config';
import SpotifyCard from './SpotifyCard/SpotifyCard';
import './EpisodePage.css';
import DisqusComments from './CommentSection/CommentSection';

export default class EpisodePage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            epi: {},
            episodes: {},
            isLoading: false,
            isLoadingContacts: false,
            error: null,
            contacts: {},
        }
    }

    componentDidMount() {

        const { id } = this.props.match.params
        if (parseInt(id) <= 0) { throw new Error('invalid id ...'); }
        this.setState({ isLoading: true });

        fetch(config.api)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data => {
                this.setState({ epi: data[id - 1], episodes: data, isLoading: false });
                document.title = this.state.epi.title;
            })
            .catch(error => this.setState({ error, isLoading: false }));

        this.setState({ isLoadingContacts: true });

        fetch(config.apiContacts)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Something went wrong ...');
                }
            })
            .then(data => this.setState({ contacts: data, isLoadingContacts: false }))
            .catch(error => this.setState({ error, isLoadingContacts: false }));

    }

    render() {
        const { epi, isLoading, error, isLoadingContacts, contacts } = this.state;

        if (error) { return <p>{error.message}</p> }
        if (isLoading || epi.img == null || epi.youtube == null || isLoadingContacts) { return <p>Loading ...</p> }

        let header =
            <div className="gridContainerTopUnder">
                <div className="gridContainerTopSpotify"></div>
            </div>

        let episodeTitle = <div className="title">{epi.title}</div>

        let embedCard;
        if (!epi.spotify_id) {
            embedCard =
                <CardMedia component="iframe"
                    className="youtubeCard"
                    src={`https://www.youtube.com/embed/${epi.youtube}?autoplay=0&origin=https://www.dragaocareca.com/`}
                    alt={epi.title}
                    allowFullScreen
                    frameBorder={0}
                />
        } else
            embedCard = <SpotifyCard spotify_id={epi.spotify_id} title={epi.title} />

        let downloadLink;
        if (epi.file != null) {
            downloadLink =
                <div className="guests-summary container-summary">
                    <h3><a href={"/files/episodes/" + epi.file} download={epi.title}>Download</a></h3>
                </div>
        }

        let description = <div className="text-summary container-summary">{epi.summary}</div>

        let links =
            <div className="container-summary">
                <h3><ContactsEmoji />Contato<ContactsEmoji /></h3>
                <a href={`mailto://${config.email}`}>{config.email}</a>
            </div>

        let citations;
        if (epi.citations != null) {
            citations =
                <div className="container-summary">
                    <h3><CitationEmoji />Citações<CitationEmoji /></h3>
                    {epi.citations.map((citation, i) => <CitationItem key={i} cite={citation} />)}
                </div>
        }

        let guests;
        if (epi.guests != null) {
            guests =
                <div className="creditsBlock">
                    <h3><PersonEmoji />Convidados<PersonEmoji /></h3>
                    {epi.guests.map((guest, i) => {
                        return <CitationItem key={i} cite={guest} />
                    })}
                </div>
        }

        let coverArtist;
        if (epi.cover_credits != null) {
            coverArtist =
                <div className="creditsBlock">
                    <h3><ArtEmoji />Arte<ArtEmoji /></h3>
                    {epi.cover_credits.map((c, i) => {
                        if (c.member) {
                            let author;
                            contacts["0"].map((a) => {
                                if (a.name === c.name) {
                                    author = a;
                                    return "";
                                }
                                return "";
                            });
                            return <CitationItem key={i} cite={author} />
                        } else
                            return <CitationItem key={i} cite={c} />
                    })}
                </div>
        }

        let musicCredits;
        if (epi.music_credits != null) {
            musicCredits =
                <div className="creditsBlock">
                    <h3><MusicEmoji />Músicas<MusicEmoji /></h3>
                    {epi.music_credits.map((m, i) => <CitationItem key={i} cite={m} mark={MusicItemEmoji} />)}
                </div>
        }

        let authors;
        if (contacts["0"] != null && contacts["0"].length > 0) {
            authors =
                <div className="creditsBlock">
                    <h3><AuthorEmoji /> Autores <AuthorEmoji /></h3>
                    {contacts["0"].map((author, index) => <AuthorItem key={index} author={author} validAuthors={epi.authors} />)}
                </div>
        }

        let footer = <div className="gridContainerBottom" style={{ backgroundImage: `url(${imgGridContainerBottom})`, marginTop: "-1px" }}></div>

        return (
            <div className="episodePage ContentPage">
                {header}
                {episodeTitle}
                {embedCard}
                <div className="summary fontSummary">
                    {downloadLink}
                    {description}
                    {/* <div className="description-summary container-summary" dangerouslySetInnerHTML={{ __html: epi.description }}/> */}
                    <DisqusComments post={{ id: epi.ID, title: epi.title }} />
                    {links}
                    {citations}
                    <div className='credits'>
                        {guests}
                        {coverArtist}
                        {musicCredits}
                        {authors}
                    </div>
                </div>
                {footer}
            </div>
        );
    }
}
