import React, { Component } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import './index.css';
import menu_up from '../../assets/img/Header/menu/menu-up.webp';
import menu_home from '../../assets/img/Header/menu/home.webp';
import menu_gallery from '../../assets/img/Header/menu/gallery.webp';
import menu_guild from '../../assets/img/Header/menu/guilda.webp';
import menu_chars from '../../assets/img/Header/menu/chars.webp';

import config from '../../config';

let scrollY = 0;
let ticking = false;
let mx = 0;
let my = 0;
let cx = 0;
const menuw = 460.517;
const menuh = 180;
const show_menu = -39; // marginTop
const menuHideSpeed = 1.5;

export default class HeadBar extends Component {
    menu_bot = React.createRef();

    constructor(props) {
        super(props)

        this.animate_menu = this.animate_menu.bind(this)

        this.state = {
            menuState: show_menu,
            zstate: 1
        }

        this.timer = null;
        this.touch = false;
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
        window.addEventListener("mousemove", this.handleMouseMove, true);
        window.addEventListener("touchstart", this.handleTouch, true);

        this.timer = setInterval(() => this.animate_menu(), 25);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('mousemove', this.handleMouseMove);

        clearInterval(this.timer);
    }

    handleMouseMove(e) {
        mx = e.clientX; // window.innerHeight;
        my = e.clientY; // window.innerWidth;
        cx = (window.innerWidth - menuw) / 2;
    }

    handleTouch(e) {
        this.touch = true;
        window.removeEventListener("touchstart", this.handleTouch, false);
    }

    animate_menu() {
        let mouse_flag = (my > 0 && my < menuh &&
            mx > cx && mx < cx + menuw);

        if (mouse_flag & (this.state.menuState !== show_menu && !this.touch) || !ticking) {
            ticking = false;
            this.setState({
                menuState: show_menu,
                zstate: 1
            });
        }
        else if (this.state.menuState > -105 && ticking) {
            if (!mouse_flag)
                this.setState({
                    menuState: this.state.menuState - menuHideSpeed,
                    zstate: 4
                });
        }
    }

    handleClick(e, href) {
        window.open(href, "_blank");
    }

    handleHome(e) {
        window.scrollTo(0, 0);
        mx = 0;
        my = 0;
    }

    handleScroll = () => {
        scrollY = window.scrollY;

        if (!ticking && scrollY > 30) {
            ticking = true;
        } else if (scrollY < 30) {
            ticking = false;
        }
    };

    hoverMenuIn = () => {
        window.requestAnimationFrame(() => {
            this.menu_bot.current.style.menuState = `${-39}px`;
        });
    };
    hoverMenuOut = () => {
        window.requestAnimationFrame(() => {
            this.menu_bot.current.style.menuState = `${-39 - scrollY / 5}px`;
        });
    };

    render() {
        return (
            <div className="headBarRoot">
                <AppBar position="fixed" className="headBar">
                    <div className="BarOut">
                        <div className="BarIn" style={{ zIndex: this.state.zstate }} />
                        <Toolbar>
                            <div className="MenuBar" >
                                <div className="MenuBarTop">
                                    <img src={menu_up} className="MenuUp" alt="MenuUp" />
                                    <Link to="/" className="MenuHomeIcon" title="Início"
                                        onClick={(e) => this.handleHome(e)}>
                                        <img src={menu_home} className="headerLogo" alt="Home" />
                                    </Link>
                                </div>
                                <div className="MenuBarBot"
                                    ref={this.menu_bot}
                                    style={{ marginTop: this.state.menuState }}
                                >
                                    <Link to="" className="MenuCharIcon MenuIcons" title="Personagens"
										onClick={(e) => this.handleClick(e, config.fichasDominio)}>
                                        <img src={menu_chars} className="menuCharsIcon" alt="MenuIconChars" />
                                    </Link>
                                    <Link to="" className="MenuGalleryIcon MenuIcons" title="Galeria"
                                        onClick={(e) => this.handleClick(e, config.social.instagram)}>
                                        <img src={menu_gallery} className="menuGalleryIcon" alt="MenuGalleryIcon" />
                                    </Link>
                                    <Link to="/guilda" className="MenuScrollIcon MenuIcons" title="Padrim">
                                        <img src={menu_guild} className="menuScrollIcon" alt="MenuScrollIcon" />
                                    </Link>
                                </div>
                            </div>
                        </Toolbar>
                    </div>
                </AppBar>
            </div>
        );
    }
}
